import { Divider, Grid, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCategory } from '../../api/category'
import {
  createProduct,
  getImgUrls,
  getProduct,
  putProduct,
} from '../../api/product'
import { getSellerFee } from '../../api/seller'
import { getSellerFromLocalStorage } from '../../api/storage'
import { UnAvailableRegisterProductSnackbar } from '../../components/Snackbar'
import DragDropItems from '../../components/common/DragDropItems'
import AddProductOption from '../../components/product/AddProductOption'
import AddSetUpProduct from '../../components/product/AddSetUpProduct'
import ProductMaterial from '../../components/product/ProductMaterial'
import SelectDiscountType from '../../components/product/SelectDiscountType'
import SizeInfo, { getSizes } from '../../components/product/SizeInfo'
import { getSizeObj } from '../../components/product/sizeUtil'
import { InputMultiTextField } from '../../components/snap/SnapForm'
import { InputTextField } from '../../element/InputTextField'
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_MB } from '../../fixture/file'
import DefaultLayout from '../../global/DefaultLayout'
import useSuccessProductModal from '../../hooks/product/useSuccessProductModal'
import addPhotoIcon from '../../static/add_snap.svg'
import {
  DiscountType,
  getNameFromValue,
  getValueFromName,
  inputLabelPropsStyle,
} from '../../utils'

const AddProductPage = () => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const pid = searchParams.get('pid')
  const type = searchParams.get('type')
  const isModify = type === 'modify' ? true : false
  const navigation = useNavigate()

  const [nameLen, setNameLen] = useState(0)

  const [product, setProduct] = useState({
    productNumber: null,
    name: '',
    price: '',
    option: { options: [], inventoryCounts: [] },
    categoryId: null,
    gender: null,
    seasonIds: [],
    tags: [],
    materialMixingRate: '',
    imgs: [],
    moreImgs: [],
    sizeGuide: [],
    hsCode: '',
    originCountry: '',
    description: '',
    careInstruction: '',
    existOption: false,
    discountAmount: 0,
    discountType: DiscountType.None,
    relatedProduct: false,
    relatedProductIds: [],
    customOrder: false,
    customOrderDays: 0,
  })

  const [fee, setFee] = useState(1)

  const [onMaterialInfo, setOnMaterialInfo] = useState(true)

  const [categoryOption, setCategoryOption] = useState([])
  const [categoryMidOption, setCategoryMidOption] = useState([])

  const [genderInput, setGenderInput] = useState('')
  const [catInput, setCatInput] = useState('')
  const [midCatInput, setMidCatInput] = useState('')

  const imgInputRef = useRef()
  const moreImgInputRef = useRef()

  const { openModal, modal: AddProductSuccessModal } = useSuccessProductModal()

  const updateRelatedProduct = (newProduct) => () => {
    if (product.relatedProductIds.length >= 3) return
    if (product.relatedProductIds.some((check) => check.id === newProduct.id)) {
      // 중복 제거...
      return
    }

    setProduct((prev) => ({
      ...prev,
      relatedProductIds: [...product.relatedProductIds, newProduct],
    }))
  }

  const removeRelatedProduct = (index) => () => {
    setProduct((prev) => ({
      ...prev,
      relatedProductIds: product.relatedProductIds.filter(
        (_, i) => i !== index
      ),
    }))
  }

  const handleImgClick = (isMore) => {
    const ref = isMore ? moreImgInputRef : imgInputRef
    ref.current.click()
  }

  const handleImgChange = async (event, isMore) => {
    if (!event.target.files) {
      return alert('잘못된 이미지 형식입니다.')
    }

    for (let i = 0; i < event.target.files.length; i += 1) {
      if (event.target.files[i].size > MAX_IMAGE_SIZE) {
        return alert(
          `${event.target.files[i].name}의 사이즈가 20MB가 넘습니다.`
        )
      }
    }
    const files = event.target.files

    for (let i = 0; i < files.length; i += 1) {
      if (files[i].size > MAX_IMAGE_SIZE) {
        return alert(`${files[i].name}의 사이즈가 20MB가 넘습니다.`)
      }
    }

    const _imgs = isMore ? product.moreImgs : product.imgs

    if (isMore && _imgs.length + files.length > 20) {
      return alert('더보기 이미지는 최대 20장까지 가능합니다.')
    }
    if (!isMore && _imgs.length + files.length > 10) {
      return alert('이미지는 최대 10장까지 가능합니다.')
    }

    if (files.length > 0) {
      const validImageFiles = Array.from(files).filter((file) =>
        /\.(jpg|jpeg|png)$/.test(file.name.toLowerCase())
      )

      const { success, data } = await getImgUrls(validImageFiles)
      if (success) {
        if (isMore) {
          setProduct({ ...product, moreImgs: [..._imgs, ...data] })
        } else {
          setProduct({ ...product, imgs: [..._imgs, ...data] })
        }
      }
    }
  }

  const [shouldValidate, setValidate] = useState(false)

  const isIncorrectForm = (val) => {
    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }
    return { message: '', value: false }
  }

  const isDescriptionIncorrectForm = (val) => {
    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }

    if (val.length > 2000) {
      return { message: '2000자 이하로 입력해주세요.', value: true }
    }

    return { message: '', value: false }
  }

  const isCareInstructionIncorrectForm = (val) => {
    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }

    if (val.length > 1000) {
      return { message: '1000자 이하로 입력해주세요.', value: true }
    }

    return { message: '', value: false }
  }

  const isIncorrectSellPriceForm = (price) => {
    if (shouldValidate && Number(getSellingPrice(price)) < 5000) {
      return { message: '최소 판매금액이 5,000원 입니다.', value: true }
    }
    return {
      message: '',
      value: false,
    }
  }

  const isIncorrectStockCtnForm = (val) => {
    if (shouldValidate && !product.existOption && val === '') {
      return { message: '필수 입력값입니다.', value: true }
    }
    if (shouldValidate && Number(val) <= 0) {
      return { message: '1이상의 값을 입력해주세요.', value: true }
    }
    return { message: '', value: false }
  }

  const [pop, setPop] = useState(false)

  const clickApproveReq = async () => {
    const {
      gender,
      name,
      price,
      imgs,
      moreImgs,
      materialMixingRate,
      originCountry,
      description,
      careInstruction,
      discountAmount,
      discountType,
      relatedProductIds,
    } = product
    if (
      !gender ||
      !name ||
      Number(getSellingPrice(price)) < 5000 ||
      (!isModify && getStockCount(product) <= 0) ||
      !catInput ||
      !midCatInput ||
      !materialMixingRate ||
      !originCountry ||
      !description ||
      !careInstruction ||
      description.length > 2000 ||
      careInstruction.length > 1000
    ) {
      setPop(true)
      setValidate(true)
      return
    }

    if (
      getDiscountedSellingPrice(
        getSellingPrice(price),
        discountType,
        discountAmount
      ) < 5000
    ) {
      alert('할인된 금액은 5,000 이상이어야 합니다.')
      return
    }

    if (discountType !== DiscountType.None && discountAmount === 0) {
      alert('할인액 혹은 할인율은 0일 수 없습니다.')
      return
    }

    if (description.length > 2000) {
      alert('설명은 2000자까지 허용됩니다.')
      return
    }

    if (careInstruction.length > 1000) {
      alert('주의사항은 1000자까지 허용됩니다.')
      return
    }

    if (imgs.length === 0) {
      alert('이미지는 필수입니다.')
      return
    }

    if (moreImgs.length === 0) {
      alert('추가 이미지는 필수입니다.')
      return
    }

    const sizeGuides = product.sizeGuide.filter((o) => Array.isArray(o))

    const filledEveryOptionA = sizeGuides.every((options) => {
      const optionA = options.find((o) => {
        return o.includes('A:')
      })
      return optionA.split(':')[1] !== ''
    })

    if (!filledEveryOptionA) {
      alert('옵션 A는 필수값입니다.')
      return
    }

    const body = {
      ...product,
      sellingPrice: getSellingPrice(product.price),
      textiles: product.tags,
      sizeGuide: [
        ...sizeGuides,
        'Measurement are made with the garment laid flat. (The size may differ by 1~3cm)',
      ],
      sizeGuideImgs: [
        categoryMidOption.find((o) => o.id === product.categoryId).img,
      ],
      shipping: 'shipping info',
      relatedProductIds: relatedProductIds.map((p) => p.id),
      customOrderDays: product.customOrder ? product.customOrderDays : 0,
    }

    if (!onMaterialInfo) {
      body.seasonIds = []
      body.textiles = []
    }

    {
      const fetchProduct = isModify ? putProduct : createProduct

      const { success } = await fetchProduct(body, pid)
      if (success) {
        // navigation('/product?pop=true')
        openModal()
      } else {
        setPop(true)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      const categories = (await getCategory()).data
      const filtered = categories.filter((c) => c.parent === null)
      setCategoryOption(filtered)
      const { sellProductInfo } = getSellerFromLocalStorage()

      const { data } = await getSellerFee()
      setFee(data.fee)

      if (!search) {
        if (sellProductInfo.gender) {
          if (sellProductInfo.gender.includes('복')) {
            sellProductInfo.gender = sellProductInfo.gender.split('복')[0]
          }

          product.gender = genderOption.find(
            (o) => o.name === sellProductInfo.gender
          ).val

          setGenderInput(sellProductInfo.gender)
        }

        if (sellProductInfo.category) {
          product.categoryId = filtered.find(
            (o) => o.korName === sellProductInfo.category
          ).id

          setCatInput(sellProductInfo.category)
        }

        setProduct({ ...product })
      }

      if (search) {
        const {
          product: {
            name,
            imgs,
            moreImgs,
            gender,
            category,
            option,
            existOption,
            sellingPrice,
            textiles,
            productNumber,
            sizeGuide,
            seasons,
            materialMixingRate,
            originCountry,
            hsCode,
            description,
            careInstruction,
            discountAmount,
            discountType,
            relatedProducts,
            customOrderDays,
          },
        } = await getProduct(pid)

        setProduct({
          productNumber,
          name,
          imgs,
          moreImgs,
          gender,
          categoryId: category.id,
          option,
          price: sellingPrice + '',
          tags: textiles,
          seasonIds: seasons.map((s) => s.id),
          sizeGuide,
          materialMixingRate,
          originCountry,
          hsCode,
          description,
          careInstruction,
          existOption,
          discountAmount,
          discountType,
          relatedProduct: relatedProducts?.length !== 0 ? true : false,
          relatedProductIds: relatedProducts,
          customOrder: customOrderDays !== 0,
          customOrderDays,
        })

        if (discountType !== DiscountType.None) {
          setDiscount(true)
        }

        setGenderInput(genderOption.find((o) => o.val === gender).name)
        setCatInput(categories.find((o) => o.id === category.parent.id).korName)
        setMidCatInput(categories.find((o) => o.id === category.id).korName)
        const cur = getCategoryOrEmptyStr(filtered, category.parent.id, 'id')
        if (cur) {
          setCategoryMidOption(cur.children)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (categoryOption.length === 0) {
      return
    }
    const cur = getCategoryOrEmptyStr(categoryOption, product.categoryId, 'id')
    if (cur) {
      setCategoryMidOption(cur.children)
    }
  }, [categoryOption, product.categoryId])

  const [startIdx, setStartIdx] = useState(-1)

  const { url, options } = getSizeGuideUrlAndOption(
    categoryMidOption,
    midCatInput
  )

  useEffect(() => {
    if (!search) {
      setProduct({
        ...product,
        sizeGuide: [[`size:FREE`, ...options.map((option) => `${option}:`)]],
      })
    }
  }, [midCatInput])

  const [existDiscount, setDiscount] = useState(false)

  return (
    <DefaultLayout isLoggedIn>
      <AddProductSuccessModal />
      <UnAvailableRegisterProductSnackbar open={pop} setOpen={setPop} />
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            기본정보
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={4}
          justify="flex-end"
          alignItems="center"
          sx={{ marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              성별
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <CustomDropDownField
              fullWidth
              disableClearable
              size="small"
              value={genderInput}
              onChange={(event, value) => {
                setProduct({
                  ...product,
                  gender: genderOption.find((o) => o.name === value).val,
                })
                setGenderInput(value)
              }}
              ListboxProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              options={['', ...genderOption.map((o) => o.name)]}
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option) => option !== '' && option.includes(inputValue)
                )
              }}
              renderInput={(params) => (
                <TextField
                  error={isIncorrectForm(product.gender).value}
                  helperText={isIncorrectForm(product.gender).message}
                  {...params}
                  InputLabelProps={inputLabelPropsStyle}
                  label="성별 선택"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              카테고리(대)
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <CustomDropDownField
              fullWidth
              disableClearable
              size="small"
              value={catInput}
              onChange={(_, value) => {
                const category = getCategoryOrEmptyStr(
                  categoryOption,
                  value,
                  'korName'
                )
                if (category) {
                  setProduct({ ...product, categoryId: category.id })
                  setCatInput(value)
                  setMidCatInput('')
                }
              }}
              ListboxProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              options={['', ...categoryOption.map((o) => o.korName)]}
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option) => option !== '' && option.includes(inputValue)
                )
              }}
              renderInput={(params) => (
                <TextField
                  error={isIncorrectForm(catInput).value}
                  helperText={isIncorrectForm(catInput).message}
                  {...params}
                  InputLabelProps={inputLabelPropsStyle}
                  label="카테고리 선택"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              카테고리(중)
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <CustomDropDownField
              fullWidth
              disableClearable
              size="small"
              value={midCatInput}
              onChange={(event, value) => {
                const category = getCategoryOrEmptyStr(
                  categoryMidOption,
                  value,
                  'korName'
                )

                if (category) {
                  const { options } = getSizeGuideUrlAndOption(
                    categoryMidOption,
                    value
                  )
                  const sizes = getSizes(product.option)
                  setProduct({
                    ...product,
                    categoryId: category.id,
                    sizeGuide: sizes.map((size) => [
                      `size:${size}`,
                      ...options.map((option) => `${option}:`),
                    ]),
                  })
                  setMidCatInput(value)
                }
              }}
              ListboxProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              options={['', ...categoryMidOption.map((o) => o.korName)]}
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option) => option !== '' && option.includes(inputValue)
                )
              }}
              renderInput={(params) => (
                <TextField
                  error={isIncorrectForm(midCatInput).value}
                  helperText={isIncorrectForm(midCatInput).message}
                  {...params}
                  InputLabelProps={inputLabelPropsStyle}
                  label="카테고리 선택"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              상품명
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'end' }}>
            <InputTextField
              width="500px"
              error={isIncorrectForm(product.name).value}
              helperText={isIncorrectForm(product.name).message}
              label="상품명 입력 (영문으로 입력)"
              value={product.name}
              onChange={(e) => {
                const value = e.target.value
                if (value.length > 100) {
                  return
                }

                if (!/^$|^[A-Za-z\s,.0-9\-!@#$%^&&*()_=+'";:]+$/.test(value)) {
                  return
                }

                setProduct({ ...product, name: value })
                setNameLen(value.length)
              }}
            />
            <p
              style={{ color: '#707070', fontSize: '14px', marginLeft: '10px' }}
            >
              {nameLen}/100
            </p>
          </Grid>

          <>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                HS CODE
              </p>
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex', alignItems: 'end' }}>
              <InputTextField
                width="500px"
                label="HS CODE는 10자리입니다."
                value={product.hsCode}
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length > 10) {
                    return
                  }

                  if (!/^$|^\d+$/.test(value)) {
                    return
                  }

                  setProduct({ ...product, hsCode: value })
                }}
              />

              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  marginLeft: '30px',
                }}
              >
                HS CODE는 &nbsp;
                <a
                  href="https://unipass.customs.go.kr/clip/index.do"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  여기
                </a>
                에서 확인 가능합니다.
              </p>
            </Grid>
          </>

          <>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                원산지
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#FF0000',
                  fontWeight: 'bold',
                  marginLeft: 2,
                }}
              >
                *
              </p>
            </Grid>
            <Grid item xs={10}>
              <CustomDropDownField
                fullWidth
                disableClearable
                size="small"
                value={getNameFromValue(countryOption, product.originCountry)}
                onChange={(event, value) => {
                  const val = getValueFromName(countryOption, value)
                  setProduct({
                    ...product,
                    originCountry: val,
                  })
                }}
                ListboxProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                options={['', ...countryOption.map((o) => o.name)]}
                filterOptions={(options, { inputValue }) => {
                  return options.filter(
                    (option) => option !== '' && option.includes(inputValue)
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    error={isIncorrectForm(product.originCountry).value}
                    helperText={isIncorrectForm(product.originCountry).message}
                    {...params}
                    InputLabelProps={inputLabelPropsStyle}
                    label="원산지 선택"
                  />
                )}
              />
            </Grid>
          </>

          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              상품 번호
            </p>
          </Grid>
          <Grid item xs={10}>
            <p style={{ fontSize: '14px' }}>
              {isModify
                ? product.productNumber
                : '상품 등록 시 자동생성됩니다.'}
            </p>
          </Grid>
          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              SKU
            </p>
          </Grid>
          <Grid item xs={10}>
            <p style={{ fontSize: '14px' }}>상품 등록 시 자동생성됩니다.</p>
          </Grid>
          <Grid item xs={1.6} display="flex">
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              판매가
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10} sx={{}}>
            <Box display="flex" alignItems="end">
              <InputTextField
                width="200px"
                label="판매가 입력"
                error={isIncorrectSellPriceForm(product.price).value}
                helperText={isIncorrectSellPriceForm(product.price).message}
                value={priceWrapper(product.price)}
                onChange={(e) => {
                  const input = e.target.value
                  if (!/^[0-9,]*$/.test(input)) {
                    return
                  }
                  setProduct({ ...product, price: input })
                }}
              />
              <p
                style={{
                  color: '#707070',
                  fontSize: '14px',
                  marginLeft: '10px',
                }}
              >
                원
              </p>
            </Box>
          </Grid>

          <Grid item xs={1.6} display="flex">
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              할인
            </p>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              row
              value={existDiscount}
              onChange={(e) => {
                const existDiscount = e.target.value === 'true' ? true : false
                setDiscount(existDiscount)
                if (!existDiscount) {
                  setProduct({
                    ...product,
                    discountAmount: 0,
                    discountType: DiscountType.None,
                  })
                } else {
                  setProduct({
                    ...product,
                    discountAmount: 0,
                    discountType: DiscountType.Percentage,
                  })
                }
              }}
            >
              <MyFormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label="설정함"
              />
              <MyFormControlLabel
                value={false}
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label="설정안함"
              />
            </RadioGroup>
          </Grid>

          {existDiscount && (
            <>
              <Grid item xs={1.6} display="flex"></Grid>
              <Grid item xs={10}>
                <Box display="flex" alignItems="center">
                  원화 기준 판매가에서
                  <InputTextField
                    width="70px"
                    sx={{ height: '45px', marginX: 1 }}
                    value={
                      product.discountAmount === 0 ? '' : product.discountAmount
                    }
                    onChange={(e) => {
                      const input = e.target.value
                      if (!/^[0-9]*$/.test(input)) {
                        return
                      }

                      if (
                        product.discountType === DiscountType.None ||
                        !product.price
                      ) {
                        return
                      }

                      if (
                        product.discountType === DiscountType.Absolute &&
                        Number(input) >= getSellingPrice(product.price)
                      ) {
                        return
                      }

                      if (
                        product.discountType === DiscountType.Percentage &&
                        Number(input) > 99
                      ) {
                        return
                      }

                      setProduct({
                        ...product,
                        discountAmount: Number(input),
                      })
                    }}
                  />
                  <SelectDiscountType
                    product={product}
                    setProduct={setProduct}
                  />
                  할인
                </Box>
              </Grid>

              <Grid item xs={1.6}>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#707070',
                    fontWeight: 'bold',
                  }}
                >
                  할인가
                </p>
              </Grid>
              <Grid item xs={10}>
                <p
                  style={{ fontSize: '16px', fontWeight: 'bold', color: 'red' }}
                >
                  ₩
                  {getDiscountedPrice(
                    product.price,
                    product.discountAmount,
                    product.discountType
                  )}
                </p>
              </Grid>
            </>
          )}

          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              실제 정산가
            </p>
          </Grid>
          <Grid item xs={10}>
            <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
              ₩{' '}
              {getRealCalculatePrice(
                product.price,
                product.discountAmount,
                product.discountType,
                fee
              )}
            </p>
          </Grid>

          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              해외 판매가
            </p>
          </Grid>
          <Grid item xs={10}>
            <Box display="flex" gap={12}>
              <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                $ {getUsPrice(product.price)}
              </p>

              {existDiscount && (
                <>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#707070',
                      fontWeight: 'bold',
                    }}
                  >
                    해외 할인 판매가
                  </p>
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    $
                    {getUsPrice(
                      getDiscountedPrice(
                        product.price,
                        product.discountAmount,
                        product.discountType
                      )
                    )}
                  </p>
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={1.6} display="flex">
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
                marginBottom: '320px',
              }}
            >
              상품 설명
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <InputMultiTextField
              error={isDescriptionIncorrectForm(product.description).value}
              helperText={
                isDescriptionIncorrectForm(product.description).message
              }
              sx={{ width: '500px' }}
              id="outlined-multiline-static"
              label={
                <div>
                  상품에 대해 설명하실 내용이 있으시면 입력해주세요. (최대
                  2,000자)
                  <br />
                  <br /> *해외 이용자들이 확인하는 게시물입니다. 가급적 영문으로
                  입력 부탁드리며,
                  <br />
                  영문으로 작성이 어려우신 경우 국문으로 작성 부탁드립니다!
                </div>
              }
              multiline
              size="small"
              fullWidth
              InputProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              InputLabelProps={inputLabelPropsStyle}
              rows={16}
              value={product.description}
              onChange={(e) => {
                setProduct({ ...product, description: e.target.value })
              }}
            />
          </Grid>

          <Grid item xs={1.6} display="flex">
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
                marginBottom: '320px',
              }}
            >
              취급 시 주의사항
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <InputMultiTextField
              error={
                isCareInstructionIncorrectForm(product.careInstruction).value
              }
              helperText={
                isCareInstructionIncorrectForm(product.careInstruction).message
              }
              sx={{ width: '500px' }}
              id="outlined-multiline-static"
              label={
                <div>
                  세탁 또는 보관 시 주의사항이 있으시면 입력 해 주세요. (최대
                  1,000자)
                  <br />
                  <br />
                  *해외 이용자들이 확인하는 게시물입니다. 가급적 영문으로 입력
                  부탁드리며,
                  <br />
                  영문으로 작성이 어려우신 경우 국문으로 작성 부탁드립니다!
                </div>
              }
              multiline
              size="small"
              fullWidth
              InputProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              InputLabelProps={inputLabelPropsStyle}
              rows={16}
              value={product.careInstruction}
              onChange={(e) => {
                setProduct({ ...product, careInstruction: e.target.value })
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          margin: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            옵션 설정
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={4}
          justify="flex-end"
          alignItems="center"
          sx={{ marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              재고 수량
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'end' }}>
            <InputTextField
              width="200px"
              error={isIncorrectStockCtnForm(getStockCount(product)).value}
              helperText={
                isIncorrectStockCtnForm(getStockCount(product)).message
              }
              value={getStockCount(product)}
              disabled={product.existOption}
              onChange={(e) => {
                if (product.existOption) {
                  return
                }

                const input = e.target.value

                if (!/^[0-9]*$/.test(input)) {
                  return
                }

                setProduct({
                  ...product,
                  option: {
                    options: [{ type: 'size', list: ['FREE'] }],
                    inventoryCounts: [
                      { index: [0], count: Number(input), discount: 0 },
                    ],
                  },
                })
              }}
            />
            <p
              style={{ color: '#707070', fontSize: '14px', marginLeft: '10px' }}
            >
              개
            </p>
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              주문 제작 상품 여부
            </p>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              row
              value={product.customOrder}
              onChange={(e) => {
                setProduct({
                  ...product,
                  customOrder: e.target.value === 'true',
                })
              }}
            >
              <MyFormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{ '&.Mui-checked': { color: 'black' } }}
                  />
                }
                label="예"
              />
              <MyFormControlLabel
                value={false}
                control={
                  <Radio
                    size="small"
                    sx={{ '&.Mui-checked': { color: 'black' } }}
                  />
                }
                label="아니오"
              />
            </RadioGroup>
          </Grid>
          {product.customOrder && (
            <>
              <Grid item xs={1.6}></Grid>
              <Grid item xs={10}>
                <Box
                  sx={{
                    backgroundColor: '#F2F2F2',
                    paddingX: '30px',
                    paddingY: '30px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    주문 제작 소요일 수
                  </p>
                  <Divider sx={{ height: 8, marginBottom: 2 }} />
                  <Typography color="#ff0000" fontSize={14}>
                    주문 제작 상품 여부 선택 시 소요일수 선택은 필수입니다.
                  </Typography>
                  <RadioGroup
                    row
                    value={product.customOrderDays}
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        customOrderDays: parseInt(e.target.value),
                      })
                    }}
                  >
                    <MyFormControlLabel
                      value={7}
                      control={
                        <Radio
                          size="small"
                          sx={{ '&.Mui-checked': { color: 'black' } }}
                        />
                      }
                      label="최대 +7일"
                    />
                    <MyFormControlLabel
                      value={14}
                      control={
                        <Radio
                          size="small"
                          sx={{ '&.Mui-checked': { color: 'black' } }}
                        />
                      }
                      label="최대 +14일"
                    />
                  </RadioGroup>
                  <br />
                  <Typography color="#707070" fontSize={14}>
                    *주문 제작 소요일 수는 주문제작 후 바이스냅 물류센터로
                    발송하기까지의 기간을 뜻합니다.
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              옵션 (사이즈, 컬러 등)
            </p>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              row
              value={product.existOption}
              onChange={(e) => {
                const existOption = e.target.value === 'true' ? true : false

                if (existOption) {
                  setProduct({
                    ...product,
                    option: {
                      options: [],
                      inventoryCounts: [],
                    },
                    sizeGuide: [],
                    existOption,
                  })
                } else {
                  setProduct({
                    ...product,
                    option: {
                      options: [{ type: 'size', list: ['FREE'] }],
                      inventoryCounts: [{ index: [0], count: 0, discount: 0 }],
                    },
                    sizeGuide: [['size:FREE', ...options.map((o) => `${o}:`)]],
                    existOption,
                  })
                }
              }}
            >
              <MyFormControlLabel
                value={false}
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label="옵션 등록 안함"
              />
              <MyFormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label="옵션 등록"
              />
            </RadioGroup>
          </Grid>
          {product.existOption && (
            <>
              <Grid item xs={1.6}></Grid>
              <Grid item xs={10}>
                <Box
                  sx={{
                    backgroundColor: '#F2F2F2',
                    paddingX: '30px',
                    paddingY: '30px',
                  }}
                >
                  <AddProductOption
                    setProduct={setProduct}
                    product={product}
                    sizeOptions={options}
                  />
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              관련 상품 태그 (최대 3개 상품 태그 가능)
            </p>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              row
              value={product.relatedProduct}
              onChange={(e) => {
                const relatedProduct = e.target.value === 'true' ? true : false
                setProduct((prev) => ({
                  ...prev,
                  relatedProduct,
                  relatedProductIds: [],
                }))
              }}
            >
              <MyFormControlLabel
                value={false}
                control={
                  <Radio
                    size="small"
                    sx={{ '&.Mui-checked': { color: 'black' } }}
                  />
                }
                label="태그 추가 안함"
              />
              <MyFormControlLabel
                value={true}
                control={
                  <Radio
                    size="small"
                    sx={{ '&.Mui-checked': { color: 'black' } }}
                  />
                }
                label="태그 추가"
              />
            </RadioGroup>
          </Grid>
          {product.relatedProduct && (
            <>
              <Grid item xs={1.6} />
              <Grid item xs={10}>
                <AddSetUpProduct
                  relatedProductIds={product.relatedProductIds}
                  updateRelatedProduct={updateRelatedProduct}
                  removeRelatedProduct={removeRelatedProduct}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <Box
        sx={{
          margin: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            상품 이미지 등록
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={4}
          justify="flex-end"
          alignItems="start"
          sx={{ marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              상품 이미지
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <DragDropItems
              width={150}
              height={150}
              items={product.imgs.map((img, idx) => ({
                id: idx,
                imgs: [img],
              }))}
              setItems={(items) => {
                const imgs = items.map((i) => i.imgs[0])
                setProduct({ ...product, imgs })
              }}
              front={
                <HoverImg
                  onClick={() => {
                    handleImgClick(false)
                  }}
                  alt=""
                  src={addPhotoIcon}
                />
              }
            />

            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              style={{ display: 'none' }}
              onChange={handleImgChange}
              ref={imgInputRef}
              multiple
            />

            <p style={{ fontSize: '14px', color: '#707070' }}>
              상품 상세화면 최상단에 노출되는 상품 이미지입니다.
              <br />
              이용자들의 정확한 확인을 위해 판매 상품을 중심으로 찍힌 사진을
              첨부 해 주세요.
              <br />
              <br />
              * 대표 이미지 : 업로드한 상품 이미지 목록의 첫번째 이미지
              <br />* 권장 크기 : 640 x 640 / 제한 개수 : 10개 / 장당 :{' '}
              {MAX_IMAGE_SIZE_MB}
              MB / 허용 확장자 : jpg, jpeg, png
            </p>
          </Grid>
        </Grid>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={4}
          justify="flex-end"
          alignItems="start"
          sx={{ marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              추가 이미지
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <DragDropItems
              width={150}
              height={150}
              items={product.moreImgs.map((img, idx) => ({
                id: idx,
                imgs: [img],
              }))}
              setItems={(items) => {
                const moreImgs = items.map((i) => i.imgs[0])
                setProduct({ ...product, moreImgs })
              }}
              front={
                <HoverImg
                  onClick={() => {
                    handleImgClick(true)
                  }}
                  alt=""
                  src={addPhotoIcon}
                />
              }
            />

            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              style={{ display: 'none' }}
              onChange={(e) => {
                handleImgChange(e, true)
              }}
              ref={moreImgInputRef}
              multiple
            />

            <p style={{ fontSize: '14px', color: '#707070' }}>
              상품 상세화면 ‘MORE PHOTOS’에 노출되는 상품 이미지입니다.
              <br />
              이용자들의 구매를 돕기 위해 판매 상품을 착용한 이미지 또는
              구매하는데에 도움이 될수 있는 상품 관련 이미지를 첨부 해 주세요.
              <br />
              <br />
              * 1 ~ 9번 이미지 : 등록해주신 1 ~ 9번 이미지는 상품 상세화면
              하단에 노출 됩니다. (나머지 이미지는 ‘View more’ 버튼 클릭 후 이동
              시 확인 가능)
              <br />* 권장크기 : 640 x 640 / 제한 개수 : 20개 / 장당 :{' '}
              {MAX_IMAGE_SIZE_MB}MB / 허용 확장자 : jpg, jpeg, png
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          margin: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            추가정보
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={4}
          justify="flex-end"
          alignItems="center"
          sx={{ marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              소재 혼용률
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <InputTextField
              width="500px"
              error={isIncorrectForm(product.materialMixingRate).value}
              helperText={isIncorrectForm(product.materialMixingRate).message}
              label="상품의 혼용률을 입력 해 주세요. (ex. 폴리 100%)"
              value={product.materialMixingRate}
              onChange={(e) => {
                setProduct({ ...product, materialMixingRate: e.target.value })
              }}
            />
          </Grid>
          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              소재 정보
            </p>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              row
              defaultValue="true"
              onChange={(e) => {
                setOnMaterialInfo(e.target.value === 'true' ? true : false)
              }}
            >
              <MyFormControlLabel
                value="false"
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label="추가안함"
              />
              <MyFormControlLabel
                value="true"
                control={
                  <Radio
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label="소재정보 추가"
              />
            </RadioGroup>
          </Grid>

          {onMaterialInfo && (
            <>
              <Grid item xs={1.6}></Grid>
              <Grid item xs={10}>
                <Box
                  sx={{
                    backgroundColor: '#F2F2F2',
                    paddingX: '30px',
                    paddingY: '30px',
                  }}
                >
                  <ProductMaterial product={product} setProduct={setProduct} />
                </Box>
              </Grid>
            </>
          )}

          {midCatInput === '' && (
            <Grid item xs={1.6} sx={{ display: 'flex' }}></Grid>
          )}
          {midCatInput !== '' && (
            <>
              <Grid item xs={1.6} sx={{ display: 'flex' }}>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#707070',
                    fontWeight: 'bold',
                  }}
                >
                  사이즈 정보
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#FF0000',
                    fontWeight: 'bold',
                    marginLeft: 2,
                  }}
                >
                  *
                </p>
              </Grid>

              <Grid item xs={10}></Grid>
              <Grid item xs={1.6}></Grid>
              <Grid item xs={10}>
                <Box
                  sx={{
                    backgroundColor: '#F2F2F2',
                    paddingX: '30px',
                    paddingY: '30px',
                  }}
                >
                  <SizeInfo
                    categoryMidOption={categoryMidOption}
                    catInput={catInput}
                    midCatInput={midCatInput}
                    product={product}
                    setProduct={setProduct}
                    url={url}
                    options={options}
                  />
                </Box>
              </Grid>
              <Grid item xs={1.6}></Grid>
            </>
          )}

          {categoryMidOption.length === 0 && (
            <>
              <Grid item xs={1.6}></Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={1.6}></Grid>
            </>
          )}

          <Grid item xs={10}>
            <Box marginY={0}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: '#FFF0F0',
                  color: '#F57979',
                  fontWeight: 'bold',
                  boxShadow: 'none',
                  marginY: 1.4,
                  borderRadius: 2,
                }}
                onClick={() => {
                  navigation('/product')
                  window.scrollTo(0, 0)
                }}
              >
                취소
              </Button>
              {isModify ? (
                <Button
                  size="large"
                  variant="contained"
                  onClick={clickApproveReq}
                  sx={{
                    backgroundColor: 'black',
                    fontWeight: 'bold',
                    borderRadius: 2,
                    boxShadow: 'none',
                    marginLeft: 2,
                  }}
                >
                  수정
                </Button>
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  onClick={clickApproveReq}
                  sx={{
                    backgroundColor: 'black',
                    fontWeight: 'bold',
                    borderRadius: 2,
                    boxShadow: 'none',
                    marginLeft: 2,
                  }}
                >
                  승인요청
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* </Container> */}
    </DefaultLayout>
  )
}

const CustomDropDownField = styled(Autocomplete)({
  marginTop: '10px',
  width: '300px',

  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})

export default AddProductPage

const HoverImg = styled('img')({
  cursor: 'pointer',
  width: '160px',
  height: '160px',
  margin: '0 10px',
})

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  marginRight: '100px',
  '.MuiFormControlLabel-label': checked
    ? {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px',
      }
    : {
        color: '#707070',
        fontSize: '14px',
      },
}))

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === props.value
  }

  return <StyledFormControlLabel checked={checked} {...props} />
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
}

const genderOption = [
  { name: '유니섹스', val: 'ALL' },
  { name: '남성', val: 'MEN' },
  { name: '여성', val: 'WOMEN' },
]

const countryOption = [
  { name: '대한민국', value: 'KR' },
  { name: '중국', value: 'CN' },
  { name: '인도네시아', value: 'ID' },
  { name: '베트남', value: 'VN' },
]

const getCategoryOrEmptyStr = (categories, val, keyName, isGetName) => {
  const o = categories.find((o) => o[keyName] === val)
  if (!o) return ''
  if (isGetName) return o.korName
  return o
}

export const priceWrapper = (price) => {
  price = price + ''
  price = price.replaceAll(',', '')

  let ret = ''
  if (price.length > 0) {
    for (let i = 1; i <= price.length; ++i) {
      ret = price[price.length - i] + ret

      if (i !== price.length && i % 3 === 0) {
        ret = ',' + ret
      }
    }
  }

  return ret
}

const EXCHANGE_RATE = 1300

const getSellingPrice = (price) => Number(price.replaceAll(',', ''))

export const getDiscountedSellingPrice = (
  sellingPrice,
  discountType,
  discountAmount
) => {
  if (discountType === DiscountType.Percentage) {
    return Math.round(sellingPrice * (1 - discountAmount / 100))
  } else if (discountType === DiscountType.Absolute) {
    return Math.round(sellingPrice - discountAmount)
  }

  return sellingPrice
}

export const getDiscountedPrice = (price, discountAmount, discountType) => {
  return priceWrapper(
    getDiscountedSellingPrice(
      getSellingPrice(price),
      discountType,
      discountAmount
    )
  )
}

const getRealCalculatePrice = (price, discountAmount, discountType, fee) => {
  const discountedSellingPrice = getDiscountedSellingPrice(
    getSellingPrice(price),
    discountType,
    discountAmount
  )
  return priceWrapper(Math.floor(discountedSellingPrice * (1 - fee)))
}

export const getUsPrice = (price) => {
  price = getSellingPrice(price)

  if (price < 5000) {
    return ''
  }

  const SHIPPING_PRICE_WON = 33770
  const shippingRateInPrice = getShippingRate(price)

  const sumPrice =
    (price + SHIPPING_PRICE_WON * shippingRateInPrice) / EXCHANGE_RATE
  const fee = sumPrice * 0.044 + 0.3
  return (sumPrice + fee).toFixed(2)
}

const getStockCount = (product) => {
  return product.option.inventoryCounts.reduce((acc, cur) => acc + cur.count, 0)
}

const getSizeGuideUrlAndOption = (categoryMidOption, midCatInput) => {
  const cat = categoryMidOption.find((c) => c.korName === midCatInput)
  return { url: cat?.img, options: getSizeObj(cat?.id)?.options }
}

const getShippingRate = (price) => {
  price = price / 1000

  if (price >= 333) {
    return 0.9
  } else if (price >= 297) {
    return 0.85
  } else if (price >= 280) {
    return 0.8
  } else if (price >= 270) {
    return 0.8
  } else if (price >= 260) {
    return 0.75
  } else if (price >= 258) {
    return 0.7
  } else if (price >= 244) {
    return 0.68
  } else if (price >= 230) {
    return 0.65
  } else if (price >= 220) {
    return 0.4
  } else if (price >= 210) {
    return 0.4
  } else if (price >= 200) {
    return 0.4
  } else if (price >= 150) {
    return 0.55
  } else if (price >= 140) {
    return 0.6
  } else if (price >= 139) {
    return 0.6
  } else if (price >= 120) {
    return 0.6
  } else if (price >= 110) {
    return 0.7
  } else if (price >= 100) {
    return 0.75
  } else if (price >= 90) {
    return 0.8
  } else if (price >= 80) {
    return 0.8
  } else if (price >= 75) {
    return 0.75
  } else if (price >= 70) {
    return 0.4
  } else if (price >= 65) {
    return 0.4
  } else if (price >= 60) {
    return 0.4
  } else if (price >= 55) {
    return 0.4
  } else if (price >= 50) {
    return 0.42
  } else if (price >= 45) {
    return 0.44
  } else if (price >= 40) {
    return 0.46
  } else if (price >= 35) {
    return 0.47
  } else if (price >= 30) {
    return 0.48
  } else if (price >= 25) {
    return 0.49
  } else if (price >= 20) {
    return 0.5
  } else if (price >= 10) {
    return 0.5
  } else if (price >= 50) {
    return 0.5
  }

  return 0
}
