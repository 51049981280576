import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  useRadioGroup,
} from '@mui/material'
import { LeftBox, OptionContainer } from '../pages/sell/sellList'
//
const RadioSelect = ({ label, list, state, setState }) => {
  return (
    <OptionContainer>
      <LeftBox margin="auto 0 auto 30px" width={100}>
        <p
          style={{
            color: '#707070',
          }}
        >
          {label} :
        </p>
      </LeftBox>
      <RadioGroup row defaultValue="all">
        {list.map(({ name, value }, idx) => (
          <MyFormControlLabel
            key={idx}
            value="request"
            control={
              <Radio
                onClick={() => {
                  setState(value)
                }}
                size="small"
                value={value}
                checked={value === state}
                sx={{
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
            }
            label={name}
          />
        ))}
      </RadioGroup>
    </OptionContainer>
  )
}

export default RadioSelect

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  marginRight: '60px',
  '.MuiFormControlLabel-label': checked
    ? {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px',
      }
    : {
        color: '#707070',
        fontSize: '14px',
      },
}))

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === props.value
  }

  return <StyledFormControlLabel checked={checked} {...props} />
}
